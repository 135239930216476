import type { BuildInfo } from '@common/models/build-info.model';

export const buildInfo: BuildInfo = {
    commitSHA: 'a744e61d9f711a0781414ad5681ecb6edcc52cda',
    branch: 'main',
    deployEnv: 'prod',
    version: '1.0.21',
    dateFormatted: '2024-10-08--00-10',
    dateISOString: '2024-10-08T00:10:31.392Z',
};

import { Injectable } from '@angular/core';
import { UtmLocalStorage } from '@backend-types/utm';
import { SuccessfulMediaStorage } from '@common/models';
import compare from 'just-compare';

import { UtilityService } from './utility.service';

@Injectable()
export class UtmService {
    constructor(private utilityService: UtilityService) {}

    get utm(): UtmLocalStorage | null {
        return this.utilityService.getStoredObject<UtmLocalStorage>('utm_storage') || null;
    }

    // eslint-disable-next-line complexity
    set utm(utmStorage: Omit<UtmLocalStorage, 'initiated' | 'referrer'>) {
        const existingUtm = this.utm;

        if (existingUtm) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { initiated, ...existingUtmMinusInitiated } = existingUtm;
            if (!compare(existingUtmMinusInitiated, utmStorage)) {
                this.utilityService.storeObject('utm_storage', {
                    source: utmStorage.source || existingUtm.source,
                    medium: utmStorage.medium || existingUtm.medium,
                    campaign: utmStorage.campaign || existingUtm.campaign,
                    content: utmStorage.content || existingUtm.content,
                    term: utmStorage.term || existingUtm.term,
                    transactionId: utmStorage.transactionId || existingUtm.transactionId,
                    offerId: utmStorage.offerId || existingUtm.offerId,
                    initiated,
                    referrer: existingUtmMinusInitiated.referrer,
                });
            }
        } else {
            this.utilityService.storeObject('utm_storage', {
                ...utmStorage,
                initiated: new Date().toISOString(),
                referrer: this.utilityService.window.document.referrer,
            });
        }
    }

    get successfulMedia(): SuccessfulMediaStorage | null {
        return this.utilityService.getStoredObject<SuccessfulMediaStorage>('sm_storage') || null;
    }

    set successfulMedia(successfulMediaStorage: SuccessfulMediaStorage) {
        this.utilityService.storeObject('sm_storage', successfulMediaStorage);
    }

    clear() {
        this.utilityService.removeObject('utm_storage');
        this.utilityService.removeObject('sm_storage');
    }
}

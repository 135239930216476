import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    FormBuilder,
    FormControl,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
    Validators,
} from '@angular/forms';
import { Title } from '@angular/platform-browser';
import {
    OwnOrLease,
    ownOrLeaseText,
    QrsRigDetailsFormValue,
    QuoteRigSetFormValue,
    RigStorageMethod,
    rigStorageMethodText,
    VehicleUse,
    vehicleUseText,
} from '@backend-types/quote-flow-ens';
import { RocRigOwnedTenure, rocRigOwnedTenureText } from '@backend-types/roc-lib';
import { ModelFormGroup, ModelFormValue } from '@common/models';
import { AnalyticsService, AssertionService, HotKeysService, ToastService } from '@common/services';
import { validYears } from '@data/vehicle-years.data';
import { EnterVinModalComponent, VinDecodeResults } from '@modules/quote/components';
import { rigDetailOtherOption } from '@modules/quote/models';
import { QuoteRigSetFormService, VehicleInfoService } from '@modules/quote/services';
import {
    CheckMakeAvailabilityValidator,
    CheckModelAvailabilityValidator,
    CheckTrimsAvailabilityValidator,
} from '@modules/quote/validators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { format } from 'date-fns';
import {
    combineLatest,
    distinctUntilChanged,
    filter,
    Subject,
    Subscription,
    take,
    tap,
} from 'rxjs';

const MIN_YEAR = 1990;
const MAX_YEAR = parseInt(format(new Date(), 'yyyy'), 10) + 1;

@Component({
    selector: 'sbf-quote-rig-set-form-rig-details',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './quote-rig-set-form-rig-details.component.html',
    styleUrls: ['quote-rig-set-form-rig-details.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: QuoteRigSetFormRigDetailsComponent,
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: QuoteRigSetFormRigDetailsComponent,
        },
    ],
})
export class QuoteRigSetFormRigDetailsComponent
    implements OnInit, ControlValueAccessor, OnDestroy, Validator
{
    @Input() formOnly = false;
    @Output() next = new EventEmitter<void>();
    @Output() back = new EventEmitter<void>();

    minYear = MIN_YEAR;
    maxYear = MAX_YEAR;

    validYears = validYears;

    rigStorageMethodText = rigStorageMethodText;
    rocRigOwnedTenureText = rocRigOwnedTenureText;
    ownOrLeaseText = ownOrLeaseText;
    vehicleUseText = vehicleUseText;

    subscription: Subscription = new Subscription();

    activeQuoteRigSetForm!: ModelFormGroup<QuoteRigSetFormValue>;
    vinModalOpen = false;

    rigDetailsForm: ModelFormGroup<QrsRigDetailsFormValue> = this.fb.group({
        year: new FormControl<number | null>(null, [
            Validators.required,
            Validators.min(MIN_YEAR),
            Validators.max(MAX_YEAR),
        ]),
        make: new FormControl<string | null>(
            null,
            [Validators.required],
            [CheckMakeAvailabilityValidator(this.vehicleInfoService)]
        ),
        model: new FormControl<string | null>(
            null,
            [Validators.required],
            [CheckModelAvailabilityValidator(this.vehicleInfoService)]
        ),
        trim: new FormControl<string | null>(
            null,
            [Validators.required],
            [CheckTrimsAvailabilityValidator(this.vehicleInfoService)]
        ),
        yearsOwned: new FormControl<RocRigOwnedTenure | null>(null, [Validators.required]),
        ownOrLease: new FormControl<OwnOrLease | null>(null, [Validators.required]),
        vehicleUse: new FormControl<VehicleUse | null>(null, [Validators.required]),
        storageMethod: new FormControl<RigStorageMethod | null>(null, [Validators.required]),
        vin: new FormControl<string | null>(null, []),
    });

    makeFocus = new Subject<string>();
    modelFocus = new Subject<string>();
    trimFocus = new Subject<string>();

    searchYears = this.vehicleInfoService.searchYears();
    searchMakes = this.vehicleInfoService.searchMakes(this.makeFocus);
    searchModels = this.vehicleInfoService.searchModels(this.modelFocus);
    searchTrims = this.vehicleInfoService.searchTrims(this.trimFocus);

    onTouched: () => unknown = () => {};
    onChange = (rigDetails: ModelFormValue<QrsRigDetailsFormValue> | null) => {};

    constructor(
        private fb: FormBuilder,
        private hotKeysService: HotKeysService,
        private assertionService: AssertionService,
        private quoteRigSetFormService: QuoteRigSetFormService,
        private vehicleInfoService: VehicleInfoService,
        private changeDetectorRef: ChangeDetectorRef,
        private ngbModalService: NgbModal,
        private toastService: ToastService,
        private title: Title,
        private analyticsService: AnalyticsService
    ) {
        this.title.setTitle('Tredder Quote - Rig Details');
        this.analyticsService.sendEventCustom({
            action: 'quote_ens_rig_details',
        });
    }

    ngOnInit() {
        this.subscription.add(
            this.hotKeysService.addShortcut$({ keys: 'shift.control.ArrowRight' }).subscribe(() => {
                this.next.emit();
            })
        );
        this.subscription.add(
            this.hotKeysService.addShortcut$({ keys: 'shift.control.ArrowLeft' }).subscribe(() => {
                this.back.emit();
            })
        );
        this.subscription.add(
            this.hotKeysService.addShortcut$({ keys: 'shift.control.ArrowUp' }).subscribe(() => {
                this.yearControl.enable();
                this.makeControl.enable();
                this.modelControl.enable();
                this.trimControl.enable();
                this.rigDetailsForm.setValue({
                    year: 2019,
                    make: 'Ram',
                    model: '1500',
                    trim: 'Laramie',
                    yearsOwned: RocRigOwnedTenure.oneYearToThreeYears,
                    ownOrLease: OwnOrLease.own,
                    vehicleUse: VehicleUse.pleasure,
                    storageMethod: RigStorageMethod.driveway,
                    vin: null,
                });
                this.rigDetailsForm.markAllAsTouched();
                this.changeDetectorRef.detectChanges();
            })
        );

        this.subscription.add(
            this.quoteRigSetFormService
                .getActiveQuoteRigSetForm$()
                .subscribe((activeQuoteRigSetForm) => {
                    this.activeQuoteRigSetForm = activeQuoteRigSetForm;
                    this.changeDetectorRef.detectChanges();
                })
        );

        this.setupVehicleDetailsSubs();
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    registerOnChange(
        onChange: (rigDetails: ModelFormValue<QrsRigDetailsFormValue> | null) => unknown
    ) {
        this.onChange = onChange;

        this.subscription.add(
            this.rigDetailsForm.valueChanges
                .pipe(
                    tap(() => {
                        if (this.rigDetailsForm.touched) {
                            this.onTouched();
                        }
                    })
                )
                .subscribe(() => {
                    try {
                        const rigDetails = this._rigDetailsFormValue();
                        onChange(rigDetails);
                    } catch (error) {}
                })
        );
    }

    private setupVehicleDetailsSubs() {
        this.subscription.add(
            this.yearControl.valueChanges.subscribe((year) => {
                if (this.yearControl.valid) {
                    this.vehicleInfoService.loadVehicleData$(year);
                }
            })
        );
        this.subscription.add(
            this.vehicleInfoService.vehiclesForYear$
                .pipe(filter(() => !this.vinModalOpen))
                .subscribe(() => {
                    this.makeControl.enable();
                    this.vehicleInfoService.loadPossibles(
                        this.makeControl.value,
                        this.modelControl.value
                    );
                    this.changeDetectorRef.detectChanges();
                })
        );
        this.subscription.add(
            combineLatest([this.makeControl.valueChanges, this.modelControl.valueChanges])
                .pipe(distinctUntilChanged())
                .subscribe(([make, model]) => {
                    if (make) {
                        this.modelControl.enable({ emitEvent: false });
                    }
                    if (model) {
                        this.trimControl.enable({ emitEvent: false });
                    }
                    this.vehicleInfoService.loadPossibles(make, model);
                })
        );

        this.subscription.add(
            this.vehicleInfoService.possibleMakes$.subscribe((makes) => {
                if (this.vinModalOpen || !this.makeControl.value || makes.length === 0) {
                    return;
                } else if (
                    !makes.some((m) =>
                        m.name.toLowerCase().includes(this.makeControl.value.toLowerCase())
                    )
                ) {
                    this.makeControl.patchValue(null, { emitEvent: true });
                    this.modelControl.patchValue(null, { emitEvent: true });
                    this.trimControl.patchValue(null, { emitEvent: true });

                    this.changeDetectorRef.detectChanges();
                }
            })
        );
        this.subscription.add(
            this.vehicleInfoService.possibleModels$.subscribe((models) => {
                if (this.vinModalOpen || !this.modelControl.value || models.length === 0) {
                    if (models.length === 0) {
                        this.modelControl.disable({ emitEvent: false });
                        this.trimControl.disable({ emitEvent: false });
                    }
                    return;
                }
            })
        );
        this.subscription.add(
            this.vehicleInfoService.possibleTrims$.subscribe((trims) => {
                if (this.vinModalOpen || !this.trimControl.value || trims.length === 0) {
                    if (trims.length === 0) {
                        this.trimControl.disable({ emitEvent: false });
                    }
                    return;
                } else if (
                    trims.findIndex(
                        (trim) => trim.toLowerCase() === this.trimControl.value.toLowerCase()
                    ) === -1
                ) {
                    this.trimControl.patchValue(null, { emitEvent: true });
                    this.changeDetectorRef.detectChanges();
                }
            })
        );
    }

    registerOnTouched(onTouched: () => unknown) {
        this.onTouched = onTouched;
    }

    openVinModal() {
        this.vinModalOpen = true;
        this.ngbModalService
            .open(EnterVinModalComponent)
            .closed.pipe(take(1))
            .subscribe((vinDecodeResults?: VinDecodeResults) => {
                if (vinDecodeResults) {
                    this.vinControl.patchValue(vinDecodeResults.vin, { emitEvent: true });
                    this.makeControl.enable({ onlySelf: true });
                    this.modelControl.enable({ onlySelf: true });
                    this.trimControl.enable({ onlySelf: true });
                    this.yearControl.patchValue(vinDecodeResults.year, { emitEvent: true });
                    this.makeControl.patchValue(vinDecodeResults.make, { emitEvent: true });
                    this.modelControl.patchValue(vinDecodeResults.model, { emitEvent: true });
                    this.trimControl.patchValue(vinDecodeResults.trim, { emitEvent: true });
                    this.makeControl.markAsTouched();
                    this.modelControl.markAsTouched();
                    this.trimControl.markAsTouched();
                    const fields = ['make', 'model', 'trim'];
                    const missingFields = fields.filter((f) => !vinDecodeResults[f]);
                    if (missingFields.length > 0) {
                        this.toastService.showError({
                            body:
                                missingFields.length > 1
                                    ? `Unable to decode the following fields from VIN: ${missingFields.join(', ')}`
                                    : `Could not decode ${missingFields[0]} from VIN`,
                            options: {
                                autohide: true,
                                delay: 3000,
                                closeOnClick: true,
                            },
                        });
                    }

                    this.changeDetectorRef.detectChanges();
                }

                this.vinModalOpen = false;
            });
    }

    setDisabledState(disabled: boolean) {
        console.log(disabled);

        if (disabled) {
            this.rigDetailsForm.disable();
        } else {
            this.rigDetailsForm.enable();
            const controls = [this.makeControl, this.modelControl, this.trimControl];
            controls.forEach((control, i) => {
                if (this.yearControl.invalid || (i !== 0 && !controls[i - 1].value)) {
                    control.disable();
                }
            });
        }
    }

    writeValue(value: QrsRigDetailsFormValue | null) {
        if (value === null) {
            this.rigDetailsForm.reset();
        }
        if (value) {
            this.rigDetailsForm.setValue(value, { emitEvent: false });
            this.changeDetectorRef.detectChanges();
        }
    }

    validate(control: AbstractControl): ValidationErrors | null {
        if (this.rigDetailsForm?.invalid) {
            return { rigDetailsFormInvalid: true };
        }

        return null;
    }

    private _rigDetailsFormValue(): ModelFormValue<QrsRigDetailsFormValue> {
        const { year, make, model, trim, yearsOwned, ownOrLease, vehicleUse, storageMethod, vin } =
            this.rigDetailsForm.value;

        this.assertionService.isDefinedOrThrow(year);
        this.assertionService.isDefinedOrThrow(make);
        this.assertionService.isDefinedOrThrow(model);
        this.assertionService.isDefinedOrThrow(trim);
        this.assertionService.isDefinedOrThrow(yearsOwned);
        this.assertionService.isDefinedOrThrow(ownOrLease);
        this.assertionService.isDefinedOrThrow(vehicleUse);
        this.assertionService.isDefinedOrThrow(storageMethod);

        this.assertionService.isNotUndefinedOrThrow(vin);

        return {
            year: parseInt(year as unknown as string, 10),
            make,
            model,
            trim,
            yearsOwned,
            ownOrLease,
            vehicleUse,
            storageMethod,
            vin,
        };
    }

    /* Accessor Methods */

    get yearControl() {
        return this.rigDetailsForm.get('year') as FormControl;
    }

    get yearControlValid() {
        return (
            this.yearControl.value &&
            !(
                this.yearControl.hasError('required') ||
                this.yearControl.hasError('min') ||
                this.yearControl.hasError('max')
            )
        );
    }

    get yearControlInvalid() {
        return (
            this.yearControl.touched &&
            (this.yearControl.hasError('required') ||
                this.yearControl.hasError('min') ||
                this.yearControl.hasError('max'))
        );
    }

    get vinControl() {
        return this.rigDetailsForm.get('vin') as FormControl;
    }

    get makeControl() {
        return this.rigDetailsForm.get('make') as FormControl;
    }

    get makeControlValid() {
        return this.makeControl.valid;
    }

    get makeControlInvalid() {
        return this.makeControl.touched && this.makeControl.invalid;
    }

    get modelControl() {
        return this.rigDetailsForm.get('model') as FormControl;
    }

    get modelControlValid() {
        return this.modelControl.valid;
    }

    get modelControlInvalid() {
        return this.modelControl.touched && this.modelControl.invalid;
    }

    get trimControl() {
        return this.rigDetailsForm.get('trim') as FormControl;
    }

    get trimControlValid() {
        return this.trimControl.valid;
    }

    get trimControlInvalid() {
        return this.trimControl.touched && this.trimControl.invalid;
    }

    get hideModelControl() {
        return this.makeControl.value === rigDetailOtherOption;
    }
    get hideTrimControl() {
        return this.hideModelControl || this.modelControl.value === rigDetailOtherOption;
    }

    get yearsOwnedControl() {
        return this.rigDetailsForm.get('yearsOwned') as FormControl;
    }

    get yearsOwnedControlValid() {
        return this.yearsOwnedControl.value && !this.yearsOwnedControl.hasError('required');
    }

    get yearsOwnedControlInvalid() {
        return this.yearsOwnedControl.touched && this.yearsOwnedControl.hasError('required');
    }

    get ownOrLeaseControl() {
        return this.rigDetailsForm.get('ownOrLease') as FormControl;
    }

    get ownOrLeaseControlValid() {
        return this.ownOrLeaseControl.value && !this.ownOrLeaseControl.hasError('required');
    }

    get ownOrLeaseControlInvalid() {
        return this.ownOrLeaseControl.touched && this.ownOrLeaseControl.hasError('required');
    }

    get vehicleUseControl() {
        return this.rigDetailsForm.get('vehicleUse') as FormControl;
    }

    get vehicleUseControlValid() {
        return this.vehicleUseControl.value && !this.vehicleUseControl.hasError('required');
    }

    get vehicleUseControlInvalid() {
        return this.vehicleUseControl.touched && this.vehicleUseControl.hasError('required');
    }

    get storageMethodControl() {
        return this.rigDetailsForm.get('storageMethod') as FormControl;
    }

    get storageMethodControlValid() {
        return this.storageMethodControl.value && !this.storageMethodControl.hasError('required');
    }

    get storageMethodControlInvalid() {
        return this.storageMethodControl.touched && this.storageMethodControl.hasError('required');
    }

    // eslint-disable-next-line complexity
    get allValid() {
        return (
            this.yearControlValid &&
            this.makeControlValid &&
            this.modelControlValid &&
            this.trimControlValid &&
            this.yearsOwnedControlValid &&
            this.storageMethodControlValid &&
            this.ownOrLeaseControlValid &&
            this.vehicleUseControlValid
        );
    }
}
